export default function Header() {
    return (
        <div id="header" className="py-2 bg-gray-900">
            <div className="container mx-auto">
                <div id="logo">
                    <a className="text-white" href="#">
                        <svg width="80" id="Ebene_2" fill="currentColor" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 327">
                            <path d="m153.22,158.52c1.13-3.39,27.48-28.99,47.81-33.89,20.33-4.89,73.41-4.9,99.01,1.13,25.6,6.03,54.97-.75,75.67-17.32,20.71-16.57,6.78-29.37,2.63-32.76-33.51-18.83-75.67-2.26-86.21,9.41-10.54,11.67-35.01,21.09-45.55,24.47-10.54,3.39-53.08,1.13-53.08,1.13C217.6,33.14,226.64,0,226.64,0,226.64,0,215.34,15.81,207.06,34.64c-8.28,18.83-30.87,70.41-42.54,85.1-20.25-2.02-44.15-2.99-67.37-3.37,25.31-50.79,52.69-87.77,52.69-87.77-27.19,29.61-47.96,60.17-63.41,87.64-45.94-.42-86.42,1.25-86.42,1.25,13,5.08,47.08,8.57,79.84,10.87-22.03,42.01-30.99,74.7-30.99,81.86,0,12.33,12.33,8.06,12.33,2.84,6.52-29.04,17.62-57.94,29.8-83.96,28.47,1.81,53.9,2.73,60.73,3.07.75,17.32-22.97,42.55-22.97,42.55,0,0-8.66,19.2-22.21,29.37-32.38,3.01-63.62,25.6-57.22,54.97,6.4,29.37,30.12,25.23,41.79,21.46,1.5,8.28-4.89,19.2-11.67,20.33-6.78,1.13-34.26,10.92-38.4,14.68-4.14,3.76-8.66,13.93,21.46,10.92,30.12-3.01,48.56-34.64,56.09-46.31,9.41-45.18,33.51-102.79,36.52-106.94,3.01-4.14,1.5-9.41-1.88-14.68Zm162.09-75.39s33.66-10.91,39.83,3.32c6.16,14.22-4.74,21.81-13.75,26.08-9.01,4.27-27.97,2.37-34.14-.95-6.16-3.32-18.96-13.28,8.06-28.45Zm-227.58,184.46c-19.91-.48-23.71-19.91-18.97-29.87,4.74-9.96,18.02-16.12,18.97-17.55,0,0,25.6-9.48,26.08,8.06.47,17.54-6.16,39.83-26.08,39.36Z"/>
                            <path d="m206.5,210.59c2.24,3.86,10.21,1.24,14.81-10.83,4.6-12.08,21.29-42.95,21.29-42.95,0,0,9.46-13.82-4.36-12.7-13.82,1.12-26.51,4.98-42.57,14.19-16.06,9.21-40.7,27.64-39.96,43.57.75,15.93,18.55,14.81,27.76,7.72,9.21-7.1,29.75-21.79,31.62-22.28-.87,4.85-10.83,19.42-8.59,23.28Zm-23.4-10.71c-8.22,3.73-19.05,1.87-14.94-7.1,4.11-8.96,29.25-29.63,55.02-36.73,0,0,8.96-2.36,5.85,2.37-3.11,4.73-37.72,37.72-45.93,41.46Z"/>
                            <path d="m347.99,219.84c-15.08,1.22-28.93,5.44-46.45,15.49-17.52,10.05-44.41,30.15-43.6,47.53.82,17.39,20.24,16.17,30.29,8.42,10.05-7.74,32.46-23.77,34.5-24.31-.95,5.3-11.81,21.19-9.37,25.4,2.44,4.21,11.14,1.36,16.16-11.82,5.02-13.18,23.22-46.86,23.22-46.86,0,0,10.32-15.08-4.75-13.86Zm-10.05,15.62c-3.39,5.16-41.15,41.16-50.11,45.23-8.96,4.07-20.78,2.04-16.3-7.75,4.48-9.78,31.92-32.32,60.03-40.07,0,0,9.78-2.58,6.38,2.58Z"/>
                            <path d="m407.9,181.71h27.01c6.1-6.35.37-11.95.37-11.95l-27.76.5c-6.35,7.47.37,11.45.37,11.45Z"/>
                            <path d="m182.92,272.33c-2.84-.21-9.89,6.42-9.89,6.42-2.21,1.79-6.11,4.95-16.21,6.21-10.1,1.26-14.1-4.74-14.1-4.74-4.42-14.32,3.37-16.53,3.37-16.53,0,0,44.62-.53,48.62-.53s8.31-4.53,11.16-19.05c2.84-14.53-6.21-20.32-16.21-20.32s-19.26,2.21-30.84,11.68c-11.58,9.47-21.68,22.53-27.05,34.32-5.37,11.79.1,23.58,14.52,26,14.42,2.42,31.15-7.58,37.89-13.58,6.74-6,1.58-9.68-1.26-9.89Zm-.95-38.1c17.16-.53,11.26,17.68,11.26,17.68h-38.2s9.79-17.16,26.94-17.68Z"/>
                            <path d="m269.53,221.91c-10.1-5.05-24.63.74-28,3.05-3.37,2.32-10.95,7.89-9.47,2.32,1.47-5.58-.74-7.79-5.58-5.79-4.84,2-8.84,13.06-8.84,13.06,0,0-15.37,27.15-19.05,32.42-3.68,5.27-14.53,23.79-11.26,26.74,3.26,2.95,7.79.95,13.47-8.84,5.68-9.79,18-36.21,27.47-41.69,9.47-5.47,10.52-6.74,16.31-8.95,5.79-2.21,19.26-4.42,14.73,8.21-4.53,12.63-18.42,33.05-18.42,33.05,0,0-11.79,16.1-5.26,18.73,6.53,2.63,10.95-7.58,14.84-14.11,3.89-6.52,17.57-32.52,17.57-32.52,0,0,11.58-20.63,1.47-25.68Z"/>
                            <path d="m479.79,209.81c-6.42.42-22.73-3.37-29.99-6-7.26-2.63-16.52-3.16-20.52,6.21-4,9.37-19.89,43.16-28.52,54-8.63,10.84-19.99,20.1-29.15,18.84-9.16-1.26-15.26-3.68-9.37-18.95,5.89-15.26,9.37-18.52,9.37-18.52l12.1-21.68s3.89-7.68-2.84-8c-6.73-.32-10.42,9.47-11.26,11.47-.84,2-20.52,34.21-21.79,40.21-1.26,6-8.74,22.42,11.89,28.52,20.63,6.1,43.99-16.21,51.25-26.74,7.26-10.53,31.68-50.95,31.68-50.95,0,0-.42-2.84,14.31-2.74,14.74.11,38.1-.21,43.04-13.68-4.63.95-13.79,7.58-20.21,8Z"/>
                            <path d="m358.86,150.58c-.87-7.1-5.23-10.46-14.31-8.22-7.68,1.89-14.39,8.39-23.27,10.44,1.66-3.99,2.39-9.42-6.49-8.7-13.82,1.12-26.51,4.98-42.57,14.19-16.06,9.21-40.7,27.64-39.96,43.57.75,15.93,18.55,14.81,27.76,7.72,9.21-7.1,29.75-21.79,31.62-22.28-.87,4.85-10.83,19.42-8.59,23.28,2.24,3.86,10.21,1.24,14.81-10.83,3.54-9.28,14.2-29.64,18.95-38.58,6.11-.37,15.29-1.33,18.28-3.75,4.61-3.73,14.19-6.85,9.34,3.49-4.85,10.33-21.28,41.58-21.78,44.57-.5,2.99-1.49,4.11-1,8.34.5,4.23,8.22.12,10.33-3.98,2.12-4.11,16.81-38.22,27.64-44.32,10.83-6.1,37.59-23.65,32.11-6.47,0,0-2.74,4.73-5.23,6.85-2.49,2.12-17.3,11.95-12.57,14.19,4.73,2.24,18.17-.87,25.02-11.58,6.85-10.71,9.71-23.28,1-27.51-8.71-4.23-17.55.62-26.26,5.48-8.71,4.86-13.94,11.2-14.81,4.11Zm-53.28,7.85c-3.11,4.73-37.72,37.72-45.93,41.46-8.22,3.73-19.05,1.87-14.94-7.1,4.11-8.96,29.25-29.63,55.02-36.73,0,0,8.96-2.36,5.85,2.37Z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}