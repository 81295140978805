export default function Hero() {
    return (
        <div className="hero py-4">
            <div className="container">
                <h1 className="text-7xl">
                    We are a modern
                </h1>
            </div>
        </div>
    );
}